<template>
  <div class="flex-1 px-2">
    <FormError :errors="getError" />
    <img src="@/assets/images/blackathirat-img.png" alt="blackathirat-img" />
    <div class="flex">
      <div
        class="w-1/2 p-3"
        v-if="breeders.qr_code"
        @click="barcodeModals = true"
      >
        <qr-code
          class="custom-qr-code text-center text-sm font-proximaNormal cursor-pointer mx-auto w-10/12 md:w-6/12 text-primary"
          :text="breeders.qr_code"
        ></qr-code>
      </div>
      <div class="w-1/2 p-3">
        <HeartIcon
          class="hidden md:block bg-white rounded-md p-2 cursor-pointer"
          width="50"
          height="50"
          :iconColor="favoriteColor(breeders)"
          @click.native="clickfav(breeders)"
        />
      </div>
    </div>

    <div class="my-10 text-left relative">
      <img
        @click="openModals"
        class="cursor-pointer absolute top-0 right-0"
        src="@/assets/images/edit-icon.png"
        alt="edit-icon"
      />
      <div>
        <h5 class="font-proximaMedium text-subheaderText">Location</h5>
        <p class="w-8/12 text-sm">
          {{
            breeders.profile_streetAddress +
            " " +
            breeders.profile_city +
            " " +
            breeders.profile_state +
            " " +
            breeders.profile_postalCode +
            " " +
            breeders.profile_country
          }}
        </p>
      </div>
      <div class="mt-2 text-sm">
        <h5 class="font-proximaMedium">{{ breeders.profile_phoneNo }}</h5>
        <span>{{ breeders.profile_emailAddress }}</span>

        <!--  <img
          @click="barcodeModals = true"
          class="mx-auto mt-4 h-8 cursor-pointer"
          src="@/assets/images/barcode-lg.png"
          alt="barcode-lg"
        /> -->
      </div>
      <div
        class="mt-3 w-full text-primary px-12 py-1 border border-primary rounded-md flex items-center"
        @click="openConnectRequestModals"
      >
        <span>I’d like to connect.</span>
      </div>
    </div>
    <div class="mt-10 bg-white rounded-md py-4 text-left">
      <div class="px-4 border-l-4 border-pastelRed">
        <div>
          <h5 class="font-proximaMedium text-subheaderText">
            Research Participation
          </h5>
          <p class="w-8/12 text-sm">VGL Diversity Embark Diversity</p>
        </div>
        <div class="my-4 relative">
          <img
            @click="activeBreedModals = true"
            class="cursor-pointer absolute top-0 right-0"
            src="@/assets/images/edit-icon.png"
            alt="edit-icon"
          />
          <h5 class="font-proximaMedium text-subheaderText">Breeds:</h5>
          <p class="w-8/12 text-sm">Dobermann</p>
        </div>
        <div>
          <h5 class="font-proximaMedium text-subheaderText">Owns:</h5>
          <p class="w-8/12 text-sm">Dobermann Beauceron</p>
        </div>
      </div>
    </div>

    <!-- manage breeder profile modals -->
    <ModalsConfirm :show="manageBreederModalsComp">
      <div slot="actionQuestion" class="relative">
        <h1 class="mb-2 text-2xl">Manage Breeder Profile</h1>
        <span class="text-sm font-proximaLight"
          >Please confirm that the following information is still correct or
          else make any necessary adjustments.
        </span>
      </div>
      <div slot="actionText" class="mt-6">
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            label="Phone number*"
            placeholder=""
            v-model="breeders.profile_phoneNo"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
          <InputForm
            label="Email address*"
            placeholder=""
            v-model="breeders.profile_emailAddress"
            classAdd="w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            label="Street address*"
            placeholder=""
            v-model="breeders.profile_streetAddress"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            label="Postal code*"
            placeholder=""
            v-model="breeders.profile_postalCode"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
          <InputForm
            label="City*"
            placeholder=""
            v-model="breeders.profile_city"
            classAdd="w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <div class="w-full mr-2 flex flex-col justify-start items-start">
            <label class="text-sm font-proximaMedium" for="origin">{{
              $t("global_state") + "*"
            }}</label>
            <select
              class="w-full rounded-sm px-3 py-3 my-1 text-sm"
              v-model="breeders.profile_state"
            >
              <option class="text-lightGrey" value="" disabled selected>
                {{ $t("global_select_state") }}
              </option>
              <option
                v-for="item in statesList"
                :value="item.id"
                :key="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div
            class="w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
          >
            <label class="text-sm font-proximaMedium" for="origin">{{
              $t("global_country_of_origin")
            }}</label>
            <select
              class="w-full rounded-sm px-3 py-3 my-1 text-sm"
              v-model="breeders.profile_country"
              @change="inputSelectCountry()"
            >
              <option class="text-lightGrey" value="" disabled selected>
                {{ $t("global_select_country") }}
              </option>
              <option
                v-for="item in countriesList"
                :value="item.id"
                :key="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="mt-4 flex items-center">
          <span class="text-red">Discoverable in Directory</span>
          <RadioOnoffBorder :checked="discoverableDir" />
        </div>
      </div>
      <div slot="leftBtn">
        <BorderedButton
          class="mr-1"
          :title="$t('global_cancel')"
          borderColor="border-darkGrey"
          textCl="text-darkGrey"
          paddingX="px-8"
          width="w-full md:w-10/12"
          @click.native="closeModals"
        />
      </div>
      <div slot="rightBtn">
        <SubmitButton
          class="ml-1"
          title="Confirm"
          background="bg-success"
          paddingX="px-8"
          width="w-full md:w-10/12"
          @click.native="editBreederProfile"
        />
      </div>
    </ModalsConfirm>
    <!-- manage breeder profile modals end -->

    <ModalsConfirm :show="barcodeModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-5 -right-5 text-2xl font-proximaSemibold cursor-pointer"
          @click="barcodeModals = false"
        >
          ✖
        </div>
      </div>
      <div
        slot="actionText"
        class="w-full mt-10 flex flex-col items-center justify-center"
      >
        <div v-if="breeders.qr_code" class="rounded-xl bg-lightGrey20 p-4">
          <qr-code
            class="py-3 text-white text-center text-sm font-proximaNormal cursor-pointer mx-auto w-10/12 md:w-6/12 text-primary px-8 mt-4"
            :text="breeders.qr_code"
          ></qr-code>
          <!--  <img
            class="mx-auto"
            src="@/assets/images/barcode-lg.png"
            alt="barcode-lg"
          /> -->
        </div>
        <h5 class="mt-4 font-proximaMedium text-2xl">Scan QR Code</h5>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>

    <!-- active breed modals -->
    <ModalsConfirm :show="activeBreedModals">
      <div slot="actionQuestion" class="relative">
        <h1 class="text-2xl text-subheaderText">Active Breeds</h1>
        <span class="text-sm font-proximaLight"
          >Select from your pet records the dog breeds active in reproduction.
        </span>
      </div>
      <div slot="actionText" class="mt-6">
        <div
          class="my-2 flex items-center justify-between"
          v-for="(activeBreed, i) in activeBreeds"
          :key="i"
        >
          <div class="flex items-center">
            <img
              class="h-6"
              src="@/assets/images/profilepic-icon.png"
              alt="profilepic-icon"
            />
            <span class="ml-2">{{ activeBreed.name }}</span>
          </div>
          <RadioOnoffBorder
            :checked="activeBreed.checked"
            :checkAll="checkAll"
            @inputVal="inputVal"
          />
        </div>
        <div class="w-full md:w-11/12 mt-4 flex items-end justify-end">
          <label class="cont">
            <input type="checkbox" @change="checkAllFunc($event)" />
            <span class="checkmark"></span>
          </label>
          <span class="pl-2 font-proximaLight text-md">Select all</span>
        </div>
      </div>
      <div slot="leftBtn">
        <BorderedButton
          class="mr-1"
          :title="$t('global_cancel')"
          borderColor="border-darkGrey"
          textCl="text-darkGrey"
          paddingX="px-8"
          width="w-full md:w-10/12"
          @click.native="activeBreedModals = false"
        />
      </div>
      <div slot="rightBtn">
        <SubmitButton
          @click.native="post"
          class="ml-1"
          title="Confirm"
          background="bg-success"
          paddingX="px-8"
          width="w-full md:w-10/12"
        />
      </div>
    </ModalsConfirm>
    <!-- active breed modals -->

    <!-- I’d like to connect. -->
    <ModalsConfirm class="w-5/12" :show="connectRequestModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
          @click="closeContactRequest"
        >
          ✖
        </div>
        <h1 class="text-2xl">Contact Request</h1>
      </div>
      <div slot="actionText" class="mt-8 mb-4">
        <FormError :errors="errorForm" />
        <h2 class="text-left font-proximaLight text-xl mb-5">
          {{ $t("add_not_modals_title") }}
        </h2>
        <div>
          <textarea
            class="w-full p-2 border-2 border-primary rounded-md text-lg"
            cols="30"
            rows="5"
            v-model="noteRequest"
            placeholder="Add a note to your request to introduce yourself and explain why you want to connect."
          ></textarea>
        </div>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="closeContactRequest"
      />

      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        title="Confirm"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="submitConnectRequest"
      />
    </ModalsConfirm>
  </div>
</template>

<script>
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";
import InputForm from "../../forms/InputForm.vue";
import FormError from "../../warning/FormError.vue";
import HeartIcon from "../../icons/HeartIcon.vue";
import { mapActions, mapGetters } from "vuex";

import { getCountries } from "../../../services/countriesServices";
import { getStates } from "../../../services/stateServices";

export default {
  props: ["breeders"],
  components: {
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    RadioOnoffBorder,
    InputForm,
    FormError,
    HeartIcon,
  },
  data() {
    return {
      barcodeModals: false,
      activeBreedModals: false,
      checkAll: false,
      discoverableDir: false,
      email: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      state: "",
      country: "",
      countriesList: [],
      statesList: [],
      notes: "",
      noteRequest: "",
      activeBreeds: [
        {
          id: "1",
          name: "Airedale Terrier",
          checked: false,
        },
        {
          id: "2",
          name: "Beauceron",
          checked: false,
        },
        {
          id: "3",
          name: "Dobberman",
          checked: false,
        },
        {
          id: "4",
          name: "Dogo Argentino",
          checked: false,
        },
        {
          id: "5",
          name: "Australian Cattle Dog",
          checked: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getError: "breederdirectory/getError",
    }),
    manageBreederModalsComp() {
      return this.$store.state.manageBreederModalsState;
    },
    errorForm() {
      return this.$store.state.errorForm;
    },
    connectRequestModals() {
      return this.$store.state.addNonContactRequestState;
    },
  },
  async mounted() {
    const a = await getCountries();
    this.countriesList = a.data;
    if (this.breeders.profile_country) {
      const c = await getStates(this.breeders.profile_country);
      this.statesList = c.data;
    }
  },
  methods: {
    ...mapActions({
      updatedBreeders: "breederdirectory/updatedBreeders",
      contactRequest: "contact/contactRequest",
    }),

    submitConnectRequest() {
      const bodyData = {
        userEmailAddress: localStorage.getItem("email"),
        contactEmailAddress: this.breeders.profile_emailAddress,
        notes: this.noteRequest,
        status: 1,
      };
      this.contactRequest(bodyData);
    },

    closeContactRequest() {
      this.noteRequest = "";
      this.$store.commit("errorForm", "");
      this.$store.commit("addNonContactRequestModals", false);
    },

    editBreederProfile() {
      this.updatedBreeders(this.breeders);
    },

    openConnectRequestModals() {
      this.$store.commit("addNonContactRequestModals", true);
    },

    async clickfav(data) {
      data.is_friends_fav = !data.is_friends_fav;
      console.log(data.is_friends_fav);
      /*if (data.is_friends_fav == true) {
        await addToFavouriteServices(data.emailAddress);
      } else {
        await removeFromFavouriteServices(data.emailAddress);
      }*/
    },

    async inputSelectCountry() {
      const a = await getStates(this.breeders.profile_country);
      this.statesList = a.data;
    },

    closeModals() {
      this.$store.commit("manageBreederModals", false);
    },

    openModals() {
      this.$store.commit("manageBreederModals", true);
    },

    inputVal(val, checked) {
      this.checked = checked;
      this.$emit("settingVal", val.id, checked);
    },
    checkAllFunc(e) {
      // console.log(e.originalTarget.checked);
      this.checkAll = e.originalTarget.checked;
    },
    favoriteColor(data) {
      if (data.is_friends_fav) {
        return "#09A89D";
      } else {
        return "#9ca3af";
      }
    },
  },
};
</script>

<style scoped>
.custom-qr-code {
  color: #09a89d;
}
</style>
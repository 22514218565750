<template>
  <div>
    <div class="mb-10 w-full flex items-end justify-end">
      <div class="w-full lg:w-6/12">
        <HeaderGlobal
          titlemenu1="Community"
          :to1="'/communityoverview'"
          :titlemenu2="titlemenu2"
        />
      </div>
    </div>
    <h1 class="text-primary font-proximaMedium text-center font-bold text-2xl">
      {{ capitalizeWords(breeders.kennelName) }} Dobberman
    </h1>
    <div
      class="mt-8 grid grid-cols-2 md:grid-cols-6 flex items-start justify-center"
    >
      <div
        class="col-span-2 md:col-span-2 flex pl-20 items-start justify-center"
      >
        <FirstSection :breeders="breeders" />
        <SecondSection :breeders="breeders" />
      </div>
      <div
        class="grid grid-cols-subgrid gap-4 col-span-2 md:col-span-1 flex items-start justify-stretch"
      >
        <div class="col-start-1 items-start justify-start">
          <ThirdSection :breeders="breeders" />
        </div>
      </div>
      <div class="col-span-2 flex items-start justify-stretch">
        <!-- pets -->
        <FourthSection :breeders="breeders" />
      </div>
    </div>
  </div>
</template>

<script>
import FirstSection from "./sectionview/FirstSection.vue";
import SecondSection from "./sectionview/SecondSection.vue";
import ThirdSection from "./sectionview/ThirdSection.vue";
import FourthSection from "./sectionview/FourthSection.vue";
import HeaderGlobal from "../header/HeaderGlobal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    FirstSection,
    SecondSection,
    ThirdSection,
    FourthSection,
    HeaderGlobal,
  },
  computed: {
    ...mapGetters({
      breeders: "breederdirectory/getBreeders",
    }),
    titlemenu2() {
      return "Dog Breeder Profile: " + this.breeders.kennelName;
    },
  },
  mounted() {
    this.getBreeders(localStorage.getItem("emailBreeder"));
  },
  methods: {
    ...mapActions({
      getBreeders: "breederdirectory/getBreeders",
    }),

    capitalizeWords(str) {
      if (str)
        return str
          .split(" ")
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" ");
      else return "";
    },
  },
};
</script>

<style>
</style>